import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import { config } from "../../config";
import axios from "axios";
import "sweetalert2/dist/sweetalert2.min.css";
import Select from "react-select";
import "./../book_template/Book_Template.css";
import "../All_book/All_book.css";
import filterImg from "../../assert/icons/filter.png";

//   ///////
const Allbook = (prop) => {
  // console.log(prop);
  const [books, setbooks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage] = useState("no record");
  const [loading, setLoading] = useState(false);
  const [search_query, setsearch_query] = useState("");

  const [toggleWindow, setToggleWindow] = useState(false);

  const search_input_handler = (e) => {
    if (e.target.name === "find_book") {
      setsearch_query(e.target.value.replace("'", "`"));
    }
  };

  //------filteration----//
  const [deptData, setDeptData] = useState();
  const [subDeptData, setSubDeptData] = useState();
  const [langData, setLangData] = useState();
  const [publiYearData, setPubliYearData] = useState();
  const [authData, setAuthData] = useState();
  const [pubData, setPubData] = useState();
  const [totalPages, setTotalpages] = useState();
  const [pageNumber, setPageNumber] = useState();

  const [filters, setFilters] = useState({
    deptFil: [],
    subDeptFil: [],
    // selectedPublicationYearFil: [],
    authFil: [],
    pubFil: [],
    publicationYearFil: [],
    langFil: [],
  });
  // console.log(filters)
  async function fetchdepart() {
    let payload = {
      fn: "common_fn",
      se: "fe_dpt",
      data: { limit: null, page: null },
    };

    let reqbody = JSON.stringify(payload);

    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      // console.log(data[0])
      if (response.status === 200 && response.data.status === 0) {
        setDeptData(data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchsubdepart() {
    let payload = {
      fn: "common_fn",
      se: "fe_sub_dpt_id_filter",
      data: { limit: null, page: null, dept_id: filters.deptFil },
    };

    let reqbody = JSON.stringify(payload);
    console.log(filters.deptFil);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data;
      console.log(data);
      if (response.status == 200) {
        setSubDeptData(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchbook() {
    let payload = {
      fn: "common_fn",
      se: "fe_bo_tem",
      data: {
        limit: 10,
        page: currentPage,
        deptFil: filters.deptFil,
        subDeptFil: filters.subDeptFil,
        // selectedPublicationYearFil: filters.selectedPublicationYearFil,
        authFil: filters.authFil,
        pubFil: filters.pubFil,
        publicationYearFil: filters.publicationYearFil,
        langFil: filters.langFil,
        order: true,
      },
    };

    let reqbody = JSON.stringify(payload);

    try {
      setLoading(true);
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("books", response.data);
      setbooks(response.data.data[0]);
      console.log(books);
      // setbooks()
      setTotalpages(response.data.data[1]);
      // setSubDeptData(response.data.data[2]);
      setAuthData(response.data.data[3]);
      setPubData(response.data.data[4]);
      // setPubData(response.data.data[2]);
      setLangData(response.data.data[5]);
      setPubliYearData(response.data.data[6]);
      if (response.status === 200 && response.data.status === 0) {
        // SetData(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

 

  useEffect(() => {
    if (filters.deptFil.length > 0) {
      fetchsubdepart();
    }
  }, [filters.deptFil]);

  useEffect(() => {
    fetchdepart();
    // fetchData();
    fetchbook();
  }, [currentPage]);

  useEffect(() => {
    console.log(filters);
    setFilters({
      deptFil: [],
      subDeptFil: [],
      authFil: [],
      pubFil: [],
      publicationYearFil: [],
      langFil: [],
    });
  }, []);

  const handleFilters = (selectedOption, filName) => {
    setFilters({ ...filters, [filName]: selectedOption });
    // if(filName=='deptFil'){
    // }
    setCurrentPage(1);
    if (filName == "dept") setSubDeptData([]);
  };

  const apllyFilters = () => {
    fetchbook();
  };

  let DeptOptions = [];
  let SubDeptOptions = [];
  let LangOptions = [];
  let PublicationYearOptions = [];
  let PubOptions = [];
  let AuthOptions = [];

  DeptOptions = deptData?.map((item) => {
    return {
      value: item.deptname_hindi,
      label: item.deptname_hindi,
      id: item.row_id,
    };
  });

  SubDeptOptions = subDeptData?.map((item) => {
    console.log(item);
    return {
      value: item.subdeptname_hindi,
      label: item.subdeptname_hindi,
      id: item.subdept_id,
    };
  });

  AuthOptions = authData?.map((item) => {
    return { value: item.authorname, label: item.authorname, name: "authFil" };
  });

  PubOptions = pubData?.map((item) => {
    return { value: item.publisher, label: item.publisher, name: "pubFil" };
  });

  LangOptions = langData?.map((item) => {
    return { value: item.language, label: item.language, name: "langFil" };
  });

  PublicationYearOptions = publiYearData?.map((item) => {
    return {
      value: item,
      label: item,
      name: "publicationYear",
    };
  });

  let DeptFilteredOptions = filterOptions(DeptOptions);
  let SubDeptFilteredOptions = filterOptions(SubDeptOptions);
  let LanguageFilteredOptions = filterOptions(LangOptions);

  let PublicationYearFilteredOptions = filterOptions(PublicationYearOptions);
  let AuthFilteredOptions = filterOptions(AuthOptions);
  let PubFilteredOptions = filterOptions(PubOptions);

  function filterOptions(options) {
    let object = {};
    let array = [];
    for (let i in options) {
      // Extract the title
      let objTitle = options[i]["value"];
      // Use the title as the index
      object[objTitle] = options[i];
    }

    // Loop to push unique object into array
    for (let i in object) {
      if (object[i]["value"]) {
        array.push(object[i]);
      }
    }
    return array;
  }

  // pass data to manage_book components //
  const Book_edit_handler = (data) => {
    // console.log("edit data", data)
    prop.funupdate(data);
  };

  //--------------- export excel -----------------------//

  const [excelFields, setExcelFields] = useState({
    book_name: false,
    book_hindi_name: false,
    publisher: false,
    language: false,
  });

  useEffect(() => {
    // console.log(excelFields);
  }, [excelFields]);

  const excelcreate = () => {
    try {
      Swal.fire({
        title: "Download File",
        text: "Click the button below to download the file.",

        showCancelButton: true,
        confirmButtonText: "Download",
        confirmButtonColor: "#c34a6a",
        cancelButtonText: "Cancel",

        preConfirm: async function () {
          let payload = {
            fn: "common_fn",
            se: "exl_cr",
            data: {
              deptFil: filters.deptFil,
              subDeptFil: filters.subDeptFil,
              authFil: filters.authFil,
              pubFil: filters.pubFil,
              langFil: filters.langFil,
              publicationYearFil: filters.publicationYearFil,
            },
          };

          let reqbody = JSON.stringify(payload);

          const response = await axios.post(
            `${config.apiBaseURL}/common`,
            { payload: reqbody },
            {
              headers: { "Content-Type": "application/json" },
              responseType: "blob",
            }
          );
          console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = "booklist.xlsx";
          a.click();

          // document.body.appendChild(a);
          // a.click();
          // document.body.removeChild(a);
          // URL.revokeObjectURL(url);
        },

        didOpen: () => {
          // Modify the SweetAlert2 dialog background color directly
          const dialog = Swal.getPopup();
          dialog.style.backgroundColor = "#fffff"; // Change this to the desired background color
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  function handleYear(arg) {
    let year = "";
    if (arg.isvi_samvat && arg.isvi_samvat !== '' && arg.isvi_samvat !== null ) {
      year = arg.isvi_samvat;
    } else if (arg.vikram_samvat && arg.vikram_samvat !== '' && arg.vikram_samvat !== null ) {
      year = arg.vikram_samvat;
    } else if (arg.veer_samvat && arg.veer_samvat !== '' && arg.veer_samvat !== null ) {
      year = arg.veer_samvat;
    }
    return year;
  }

  function handleSamvat(arg) {
    let samvat = "";
    if (arg.isvi_samvat) {
      samvat = "ईस्वी सन्";
    } else if (arg.vikram_samvat) {
      samvat = "विक्रम संवत्";
    } else if (arg.veer_samvat) {
      samvat = "वीर संवत्";
    }
    return samvat;
  }

  async function search_book() {
    // if(search_query.length < 3){
    //   alert('Please enter atleast 3 characters')
    //   return;
    // }
    let payload = {
      fn: "common_fn",
      se: "sr_bo",
      data: { limit: 10, page: currentPage, search: search_query },
    };

    let reqbody = JSON.stringify(payload);
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" }, mode: "no-cors" }
      );

      console.log(response.data.data);
      const data = response.data.data;
      if (response.status === 200) {
        if (response.data.status === 0 && data.length > 0) {
          setbooks(data);
        } else if (response.data.status === 1) {
          setbooks([]);
          setErrorMessage("no record");
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Oops, something went wrong");
    } finally {
      setLoading(false);
    }
  }

  function handleCurrentPage(currentPage) {
    if (currentPage <= totalPages && currentPage >= 0) {
      setCurrentPage(Number(currentPage));
    } else {
      alert("अमान्य पृष्ठ संख्या!");
    }
  }

  return (
    <>
      {toggleWindow && (
        <div className="filterWindow">
          <div className="dialogBox">
            <div className="filters justify-left">
              <Select
                id="FilByDept"
                className="Select basic-multi-select"
                onChange={(selectedOptions) =>
                  handleFilters(selectedOptions, "deptFil")
                }
                options={DeptFilteredOptions}
                value={filters.deptFil}
                isSearchable
                isMulti
                placeholder="Filter By Department"
                // onInputChange={handleSearchTermChange}
                // styles={valid ? customStyles2 : customStyles}
              />
              <Select
                id="subDepart"
                className="Select"
                onChange={(selectedOptions) =>
                  handleFilters(selectedOptions, "subDeptFil")
                }
                options={SubDeptFilteredOptions}
                value={filters.subDeptFil}
                isSearchable
                isMulti
                placeholder="Filter By Sub-Department"
                // onInputChange={handleSearchTermChange}
              />
              <Select
                id="author"
                className="Select basic-multi-select"
                onChange={(selectedOptions) =>
                  handleFilters(selectedOptions, "authFil")
                }
                options={AuthFilteredOptions}
                value={filters.authFil}
                isSearchable
                isMulti
                placeholder="Filter By Author"
              />
              <Select
                id="publisher"
                className="Select basic-multi-select"
                onChange={(selectedOptions) =>
                  handleFilters(selectedOptions, "pubFil")
                }
                options={PubFilteredOptions}
                value={filters.pubFil}
                isSearchable
                isMulti
                placeholder="Filter By Publisher"
              />
              {/* <Select
                id="PubYear"
                className="Select basic-multi-select"
                onChange={(selectedOptions) =>
                  handleFilters(selectedOptions, "publicationYearFil")
                }
                options={PublicationYearFilteredOptions}
                value={filters.publicationYearFil}
                isSearchable
                isMulti
                placeholder="Filter By Publication Year"
              /> */}

              <Select
                id="FilByLang"
                className="Select"
                onChange={(selectedOptions) =>
                  handleFilters(selectedOptions, "langFil")
                }
                options={LanguageFilteredOptions}
                value={filters.langFil}
                isSearchable
                isMulti
                placeholder="Filter By Language"
              />
            </div>
            <div className="dialogBox_btns">
              <button
                className="applyBtn"
                onClick={() => {
                  apllyFilters();
                  setToggleWindow(false);
                }}
              >
                Apply
              </button>
              <button
                onClick={() => {
                  setToggleWindow(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="filterContainer">
        <button
          className="goBackBtn"
          onClick={() => {
            prop.goBack(false);
          }}
        >
          &lt;- Go Back
        </button>
        <div className="filterSortingBtns">
          <button
            className="filterBtn"
            onClick={() => {
              setToggleWindow(true);
            }}
          >
            <img src={filterImg} alt="filterImg" />
            Filters
          </button>

        </div>
      </div>
      {loading ? (
        <BeatLoader color="#123abc" className="spiner-postion" />
      ) : (
        <div className="rack-list-container">
          {books && !books.length > 0 ? (
            <p> {errorMessage} </p>
          ) : (
            <>
              <table className="rack-table">
                <thead>
                  <tr>
                    <th>Book No.</th>
                    <th>
                      Book Name
                      <input
                        className="search_input"
                        type="text"
                        id="find_book"
                        name="find_book"
                        placeholder="..type"
                        value={search_query}
                        onChange={search_input_handler}
                      />
                      <button className="search-find" onClick={search_book}>
                        Find
                      </button>
                    </th>
                    <th>Book Author</th>
                    <th>Department</th>
                    <th>Sub Department</th>
                    <th>Author</th>
                    <th>Publisher</th>
                    <th>Rack Name</th>
                    <th>Selected Publication Year</th>
                    <th>Publication Year</th>
                    <th>Language</th>
                    <th>Size</th>
                    <th>Shape</th>
                    <th>Quality</th>
                    <th>Binding</th>
                    <th>Title Details</th>
                    <th>ISBN Number</th>
                    <th>status</th>

                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {books?.map((book) => (
                    <tr key={book.row_id} className="rack-row">
                      <td>{book.book_no}</td>
                      <td className="rack-name" style={{ fontSize: "13px" }}>
                        {book.bookname} <br></br> {book.book_hindi_name}
                      </td>
                      <td className="rack-description">{book.authorname}</td>
                      <td className="rack-name">
                        {book.deptname}
                        <br></br> {book.deptname_hindi}
                      </td>
                      <td className="rack-name">
                        {book.subdeptname} <br></br> {book.subdeptname_hindi}
                      </td>
                      <td className="rack-name">{book.authorname} </td>
                      <td className="rack-name">{book.publisher} </td>
                      <td className="rack-name">
                        {book?.room}-{book?.almari}-{book?.rackname}
                      </td>
                      <td className="rack-name">{handleSamvat(book)}</td>
                      <td className="rack-name">{handleYear(book)}</td>
                      <td className="rack-name">{book.language} </td>
                      <td className="rack-name">{book.size} </td>
                      <td className="rack-name">{book.shape} </td>
                      <td className="rack-name">{book.quality} </td>
                      <td className="rack-name">{book.binding} </td>
                      <td className="rack-name">{book.title_detail} </td>
                      <td className="rack-name">{book.isbn} </td>
                      <td className={book.avail_status === 1 ? "issued" : ""}>
                        {book.avail_status === 1 ? "Issued" : "available"}
                      </td>

                      <td className="rack-name">
                        <button
                          onClick={() => Book_edit_handler(book)}
                          disabled={book.avail_status === 1 ? true : false}
                          className={`btn-edit ${
                            book.avail_status === 1 ? "btn-edit2" : ""
                          }`}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      )}
      <div className="sub-page24">
        <div>
          <button
            className={`${
              currentPage === 1 ? "pagination-btn-disabled" : "pagination-btn "
            }`}
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="Page-text">
            {" "}
            Page {currentPage}/{totalPages}{" "}
          </span>
          <button
            className={`${
              currentPage === totalPages || books.length < 10
                ? "pagination-btn-disabled"
                : "pagination-btn "
            }`}
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={
              currentPage === totalPages || currentPage < 1 || books.length < 10
            }
          >
            Next
          </button>
          <br />
          <button
            className="page_number_button"
            onClick={(e) => {
              handleCurrentPage(pageNumber);
            }}
          >
            Go to page number{" "}
          </button>
          <input
            type="text"
            className="page_number"
            placeholder="1"
            onChange={(e) => {
              setPageNumber(e.target.value);
            }}
          />
        </div>
        <div>
          {" "}
          <button
            className="pagination-btn"
            style={{ backgroundColor: "green", color: "white" }}
            onClick={excelcreate}
          >
            Export as Excel
          </button>{" "}
        </div>
      </div>
    </>
  );
};

export default Allbook;
