import React, { useEffect, useState } from "react";
import Book_template from "./Book_Template";
import Select from "react-select";
import "./../book_template/Book_Template.css";
import axios from "axios";
import { config } from "../../config";

const Share_Books = () => {
  const [data, SetData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    // pubFil: [],
    // authFil: [],
    // langFil: [],
    deptFil: [],
    subDeptFil: [],
  });
  // const [authData, setAuthData] = useState();
  // const [pubData, setPubData] = useState();
  // const [langData, setLangData] = useState();

  const [deptData, setDeptData] = useState();
  const [subDeptData, setSubDeptData] = useState();
  const [totalPages, setTotalpages] = useState();

  useEffect(() => {
    setCurrentPage(1);
  }, [filters]);

  async function fetchdepart() {
    let payload = {
      fn: "common_fn",
      se: "fe_dpt",
      data: { limit: null, page: null },
    };

    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );

      const data = response.data.data;
      if (response.status === 200 && response.data.status === 0) {
        setDeptData(data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // console.log(deptData)

  // async function fetchsubdepart(value) {
  //   console.log(value)
  //   let payload = {
  //     fn: "common_fn",
  //     se: "fe_sub_dpt_id",
  //     data: { dept_id: value },
  //   };
  //   let reqbody = JSON.stringify(payload);
  //   // Fetch racks data from
  //   try {
  //     const response = await axios.post(
  //       `${config.apiBaseURL}/common`,
  //       { payload: reqbody },
  //       { headers: { "Content-Type": "application/json" } }
  //     );

  //     const data = response.data.data;
  //     // console.log(data);
  //     if (response.status === 200 && response.data.status === 0) {
  //       setSubDeptData(data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // console.log(subDeptData)

  async function fetchbook() {
    let payload = {
      fn: "common_fn",
      se: "fe_bo_tem",
      data: {
        limit: 12,
        page: currentPage,
        deptFil: filters.deptFil,
        subDeptFil: filters.subDeptFil,
      },
    };

    let reqbody = JSON.stringify(payload);
    // Fetch racks data from
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.apiBaseURL}/common`,
        { payload: reqbody },
        { headers: { "Content-Type": "application/json" } }
      );
      const data = response.data.data[0];
      const totalPages = response.data.data[1];
      setTotalpages(totalPages);
      setSubDeptData(response.data.data[2]);
      // setPubData(response.data.data[2]);
      // setLangData(response.data.data[3]);
      if (response.status === 200 && response.data.status === 0) {
        console.log(data);
        SetData(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }
  // console.log(subDeptData)
  useEffect(() => {
    // setCurrentPage(1)
    fetchdepart();
    fetchbook();
  }, [currentPage, filters]);



  const handleDepartmentChange = (selectedOption) => {
    setFilters({ ...filters, ["deptFil"]: selectedOption });

    setSubDeptData([]);
  };

  const handleSubDepartmentChange = (selectedOption) => {
    setFilters({ ...filters, ["subDeptFil"]: selectedOption });
  };

  let DeptOptions = [];
  let SubDeptOptions = [];

  DeptOptions = deptData?.map((item) => {
    return {
      value: item.deptname_hindi,
      label: item.deptname_hindi,
      id: item.row_id,
    };
  });

  SubDeptOptions = subDeptData?.map((item) => {
    return {
      value: item.subdeptname_hindi,
      label: item.subdeptname_hindi,
      id: item.row_id,
    };
  });

  let DeptFilteredOptions = filterOptions(DeptOptions);
  let SubDeptFilteredOptions = filterOptions(SubDeptOptions);

  function filterOptions(options) {
    let object = {};
    let array = [];
    for (let i in options) {
      // Extract the title
      let objTitle = options[i]["value"];
      // Use the title as the index
      object[objTitle] = options[i];
    }

    // Loop to push unique object into array
    for (let i in object) {
      if (object[i]["value"]) {
        array.push(object[i]);
      }
    }
    return array;
  }

  return (
    <>
      <div className="filterContainer">
        <div className="filters">
          <Select
            id="FilByDept"
            className="Select basic-multi-select"
            onChange={(selectedOptions) =>
              handleDepartmentChange(selectedOptions)
            }
            options={DeptFilteredOptions}
            isSearchable
            isMulti
            placeholder="विभाग द्वारा फ़िल्टर करें"
            // onInputChange={handleSearchTermChange}
            // styles={valid ? customStyles2 : customStyles}
          />

          <Select
            id="FilByPublis"
            className="Select"
            onChange={(selectedOptions) =>
              handleSubDepartmentChange(selectedOptions)
            }
            options={SubDeptFilteredOptions}
            isSearchable
            isMulti
            placeholder="उपविभाग द्वारा फ़िल्टर करें"
            // onInputChange={handleSearchTermChange}
            // styles={valid ? customStyles2 : customStyles}
          />
        </div>

        <Book_template
          data={data}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          loading={loading}
          filters={filters}
        />
      </div>
    </>
  );
};

export default Share_Books;
