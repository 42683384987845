import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./menubar.css";
import issuebook from "../../assert/icons/issue_book.png";
import managebook from "../../assert/icons/manage_book.png";
import addbook from "../../assert/icons/manage_book (2).png";
import managerack from "../../assert/icons/book-shelf.png";
import upcoming from "../../assert/icons/return.png";
import upcoming2 from "../../assert/icons/return (2).png";
import notreturn from "../../assert/icons/book-keeping.png";
import logout from "../../assert/icons/logout.png";
import importBooks from "../../assert/icons/import_books.png";
import shareBook from "../../assert/icons/share.png";
import shareBook2 from "../../assert/icons/share (2).png";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function MenuBar(prop) {
  const history = useHistory();
  // const [activeLink, setActiveLink] = useState("");

  // const handleClick = (link) => {
  //   setActiveLink(link);
  //   prop.setupdate(Date.now());
  // };

  const Logouthandler = () => {
    localStorage.removeItem("token");
    history.push("/login");
  };

  return (
    <>
      <div className="left-side-menu">
        <div className="logo">{/* Your logo here */}</div>
        <div className="menu-items">
          <NavLink
            className="menu-item"
            exact={true}
            to="/admin"
            id="link-1"
            activeClassName="active"
            // onClick={() => handleClick("/admin/issuebook")}
          >
            <img src={issuebook} alt="isssue" />
            <span className="menu-title">Issue Book</span>
          </NavLink>

          <NavLink
            className="menu-item"
            to="/admin/managebook"
            id="link-2"
            activeClassName="active"
          >
            <img src={addbook} alt="book" />
            <span className="menu-title">Manage Books</span>
          </NavLink>

          <NavLink
            className="menu-item"
            to="/admin/importbooks"
            id="link-3"
            activeClassName="active"
          >
            <img src={importBooks} alt="book" />
            <span className="menu-title">Import Books</span>
          </NavLink>

          <NavLink
            className="menu-item"
            to="/admin/department"
            id="link-4"
            activeClassName="active"
          >
            <img src={managebook} alt="book" />
            <span className="menu-title">Manage Department</span>
          </NavLink>

          <NavLink
            className="menu-item"
            to="/admin/subdepartment"
            id="link-5"
            activeClassName="active"
          >
            <img src={managebook} alt="book" />
            <span className="menu-title">Manage Sub Department</span>
          </NavLink>

          <NavLink
            className="menu-item"
            to="/admin/managerack"
            id="link-6"
            activeClassName="active"
          >
            <img src={managerack} alt="rack" />
            <span className="menu-title">Manage Racks</span>
          </NavLink>
          
          <NavLink
            className="menu-item"
            to="/admin/not-return-book"
            id="link-7"
            activeClassName="active"
          >
            <img src={notreturn} alt="Contact" />
            <span className="menu-title">Return Book</span>
          </NavLink>

          <NavLink
            className="menu-item"
            to="/admin/upcoming-books-return"
            id="link-8"
            activeClassName="active"
          >
            <img src={upcoming} alt="upcoming"/>
            <span className="menu-title">Upcoming Books Return</span>
          </NavLink>

          <NavLink
            className="menu-item"
            to="/admin/book-template"
            id="link-9"
            activeClassName="active"
          >
            <img src={shareBook2} alt="upcoming"/>
            <span className="menu-title">Share Books</span>
          </NavLink>

          <NavLink
            className="menu-item"
            to="/admin/book-template-without-summary"
            id="link-9"
            activeClassName="active"
          >
            <img src={shareBook2} alt="upcoming"/>
            <span className="menu-title">Share Books Without Summary</span>
          </NavLink>

          <div className="menu-item" onClick={() => document.querySelector("#link-10").click()}>
            <Link style={{color: 'white'}} to="#" onClick={Logouthandler} id="link-10">
              <img src={logout} alt="logout" />
              <span className="menu-title">Logout</span>
            </Link>            
          </div>

        </div>
      </div>
    </>
  );
}

export default MenuBar;
