import { React, useState } from "react";
import MenuBar from "../menubar/menubar";
import login from "../../page/Login/login";
import Issuebook from "../../page/Issuebook/issuebook";
import Manage_Book from "../../page/manage_book/Manage_Book";
import Managerack from "../../page/manage_rack/managerack";
import Import_books from "../../page/import_books_excel/import_books";
import Allbook from "../../page/All_book/All_book";
import Not_return_book from "../../page/return_book/return_book";
import Upcoming_books_return from "../../page/upcoming_book_return/upcoming_book_return";
import Department from "./../../page/manage_dept/Department";
import Subdepartment from "../../page/Sub_department/Sub_dept";
import ShareBooksWithoutSummary from "../../page/book_template/Share_books_Without_Summary";
// import Book_template_without_summary from "../../page/book_template/Book_template_without_summary";
// import Book_template from "../../page/book_template/book_template";

import "./layout.css";
import { BrowserRouter as Routes, Switch, Route, Link } from "react-router-dom";
import RackList from "../../page/manage_rack/racklist";
// import Testing2 from "../../page/manage_book/Testing2";
import Share_Books from "../../page/book_template/Share_Books";

const Layout = () => {
  const [update, setupdate] = useState(Date.now());
  return (
    <div>
      <div className="row m-0 headers">
        <h3 className="heading-title">Library Manangement System</h3>
      </div>
      <div className="row m-0" style={{ height: "100%", overflow: "hidden" }}>
        <div className="col-3  navbar-div" style={{}}>
          <MenuBar setupdate={setupdate} />
        </div>
        <div
          className="col-9 border primary  content-table"
          style={{ padding: "10px" }}
        >
          {/* <Switch key={update}> */}
          {/* <Route path={"/admin"} exact={true} component={login} /> */}
          
          <Route path={"/admin"} exact={true} component={Issuebook} />
          
          <Route
            path={"/admin/importbooks"}
            exact={true}
            component={Import_books}
          />

          <Route
            path={"/admin/managebook"}
            exact={true}
            component={Manage_Book}
          />
          
          <Route
            path={"/admin/managerack"}
            exact={true}
            component={Managerack}
          />

          <Route path={"/admin/Allbook"} exact={true} component={Allbook} />

          <Route
            path={"/admin/not-return-book"}
            exact={true}
            component={Not_return_book}
          />

          <Route
            path={"/admin/upcoming-books-return"}
            exact={true}
            component={Upcoming_books_return}
          />
          <Route path={"/admin/racklist"} exact={true} component={RackList} />

          <Route
            path={"/admin/department"}
            exact={true}
            component={Department}
          />

          <Route
            path={"/admin/subdepartment"}
            exact={true}
            component={Subdepartment}
          />

          <Route
            path={"/admin/book-template"}
            exact={true}
            component={Share_Books}
          />

<Route
            path={"/admin/book-template-without-summary"}
            exact={true}
            component={ShareBooksWithoutSummary}
          />

          {/* <Route path={"/admin/status"} exact={true} component={Status} />
              <Route path={"/admin/client/view/:id"} component={ViewClient} />  */}
          {/* </Switch> */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
